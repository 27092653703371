import { Stack, Image } from '@chakra-ui/react'

import Line from '../../assets/line.png'
import FAQ from '../faq'
import Fotos from '../fotos'


import img_borboleta from "../../assets/elementos/borboleta.png"
import img_olho from "../../assets/elementos/olho.png"
import img_olho_diag from "../../assets/elementos/olho_diag.png"
import img_olho_diag2 from "../../assets/elementos/olho_diag2.png"
import img_olho_diag2_inv from "../../assets/elementos/olho_diag2_inv.png"
import img_planta from "../../assets/elementos/planta.png"
import img_planta_inv from "../../assets/elementos/planta_inv.png"

const Homepage = () => {
  const display_elements = ((window.innerWidth > 800) ? "" : "none")

    return (
      <Stack
        align="center" justify="center" spacing={"0px"} width={"100vw"}
      >
        <Image src={Line} alt={"Logo TUSCA"} width={"100vw"} />

        <Fotos />
        <FAQ />



        <Image display={display_elements} src={img_olho_diag} position={"absolute"} width="150px" left={"50px"} top={"2380px"}/>
        <Image display={display_elements} src={img_olho} position={"absolute"} width="250px" left={"5px"} top={"2480px"}/>
        <Image display={display_elements} src={img_olho_diag2} position={"absolute"} width="200px" left={"50px"} top={"2680px"}/>
        <Image display={display_elements} src={img_olho_diag2_inv} position={"absolute"} width="200px" right={"50px"} top={"2680px"}/>

        <Image display={display_elements} src={img_planta} position={"absolute"} width="200px" left={"0px"} bottom={"200px"}/>
        <Image display={display_elements} src={img_planta_inv} position={"absolute"} width="200px" right={"0px"} bottom={"200px"}/>

        <Image display={display_elements} src={img_borboleta} position={"absolute"} width="150px" left={"40px"} bottom={"20px"}/>
        <Image display={display_elements} src={img_borboleta} position={"absolute"} width="125px" left={"5px"} bottom={"150px"}/>
        <Image display={display_elements} src={img_borboleta} position={"absolute"} width="100px" left={"150px"} bottom={"200px"}/>



      </Stack>
  );
  }
    
  export default Homepage;