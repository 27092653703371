import { Heading, Stack, Image, Flex, Text, Box, AspectRatio } from "@chakra-ui/react"
import { useState, useEffect } from "react"

import Textura from "../../assets/textura_1.png"
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons'

const imagelist = [
  "https://res.cloudinary.com/ddojzbfuy/image/upload/v1722693683/CORSO-23_l2fe34.jpg",
  "https://res.cloudinary.com/ddojzbfuy/image/upload/v1722693679/CORSO-20_bp5fe5.jpg",
  "https://res.cloudinary.com/ddojzbfuy/image/upload/v1722693678/CORSO-15_qg7csn.jpg",
  "https://res.cloudinary.com/ddojzbfuy/image/upload/v1722693677/CORSO-10_l0vaxa.jpg",
  "https://res.cloudinary.com/ddojzbfuy/image/upload/v1722693676/3Dia-Festa-415_ny32li.jpg",
  "https://res.cloudinary.com/ddojzbfuy/image/upload/v1722693677/4Dia-Tuscazone-35_cznlhb.jpg",
  "https://res.cloudinary.com/ddojzbfuy/image/upload/v1722693676/4Dia-Tuscazone-6_bt2q4e.jpg",
  "https://res.cloudinary.com/ddojzbfuy/image/upload/v1722693676/3Dia-Festa-313_xqevcn.jpg",
  "https://res.cloudinary.com/ddojzbfuy/image/upload/v1722693676/3Dia-Festa-236_z9tbd4.jpg",
  "https://res.cloudinary.com/ddojzbfuy/image/upload/v1722693675/3Dia-Festa-5_eslhd8.jpg",
  "https://res.cloudinary.com/ddojzbfuy/image/upload/v1722693676/3Dia-Festa-343_rdxuie.jpg",
  "https://res.cloudinary.com/ddojzbfuy/image/upload/v1722693675/3Dia-Festa-21_r1p2c7.jpg",
  "https://res.cloudinary.com/ddojzbfuy/image/upload/v1722693675/3Dia-Festa-29_f7czdf.jpg",
  "https://res.cloudinary.com/ddojzbfuy/image/upload/v1722693674/3Dia-Arena-370_k2m2cf.jpg",
  "https://res.cloudinary.com/ddojzbfuy/image/upload/v1722693674/3Dia-Arena-361_pqmwmx.jpg",
  "https://res.cloudinary.com/ddojzbfuy/image/upload/v1722693674/3Dia-Arena-418_m2guv4.jpg",
  "https://res.cloudinary.com/ddojzbfuy/image/upload/v1722693674/3Dia-Arena-284_rnp4jw.jpg",
  "https://res.cloudinary.com/ddojzbfuy/image/upload/v1722693668/3Dia-Arena-263_gqcepp.jpg",
  "https://res.cloudinary.com/ddojzbfuy/image/upload/v1722693669/3Dia-Arena-268_h8dgjj.jpg",
  "https://res.cloudinary.com/ddojzbfuy/image/upload/v1722693668/3Dia-Arena-264_oczwfq.jpg",
  "https://res.cloudinary.com/ddojzbfuy/image/upload/v1722693668/3Dia-Arena-262_ph0sa6.jpg",
  "https://res.cloudinary.com/ddojzbfuy/image/upload/v1722693667/3Dia-Arena-261_pqcn63.jpg",
  "https://res.cloudinary.com/ddojzbfuy/image/upload/v1722693667/3Dia-Arena-195_r6a35a.jpg",
  "https://res.cloudinary.com/ddojzbfuy/image/upload/v1722693666/3Dia-Arena-77_b5s4un.jpg",
  "https://res.cloudinary.com/ddojzbfuy/image/upload/v1722693666/3Dia-Arena-37_m9ynvh.jpg",
  "https://res.cloudinary.com/ddojzbfuy/image/upload/v1722693667/3Dia-Arena-119_qdmot7.jpg",
  "https://res.cloudinary.com/ddojzbfuy/image/upload/v1722693666/3Dia-Arena-182_sintwk.jpg",
  "https://res.cloudinary.com/ddojzbfuy/image/upload/v1722693665/2Dia-Festa-672_kimitz.jpg",
  "https://res.cloudinary.com/ddojzbfuy/image/upload/v1722693665/2Dia-Festa-672_kimitz.jpg",
  "https://res.cloudinary.com/ddojzbfuy/image/upload/v1722693665/3Dia-Arena-9_exsjy8.jpg",
  "https://res.cloudinary.com/ddojzbfuy/image/upload/v1722693665/2Dia-Festa-664_gq6qxa.jpg",
  "https://res.cloudinary.com/ddojzbfuy/image/upload/v1722693665/2Dia-Festa-669_z1ccjo.jpg",
  "https://res.cloudinary.com/ddojzbfuy/image/upload/v1722693665/2Dia-Festa-680_lu4j2i.jpg",
  "https://res.cloudinary.com/ddojzbfuy/image/upload/v1722693664/2Dia-Festa-651_fqofrh.jpg",
  "https://res.cloudinary.com/ddojzbfuy/image/upload/v1722693664/2Dia-Festa-567_nenrf4.jpg",
  "https://res.cloudinary.com/ddojzbfuy/image/upload/v1722693663/2Dia-Festa-537_wcysav.jpg",
  "https://res.cloudinary.com/ddojzbfuy/image/upload/v1722693663/2Dia-Festa-298_r2rfo1.jpg",
  "https://res.cloudinary.com/ddojzbfuy/image/upload/v1722693664/2Dia-Festa-581_wqonz7.jpg",
  "https://res.cloudinary.com/ddojzbfuy/image/upload/v1722693663/2Dia-Festa-542_lvpq8g.jpg",
  "https://res.cloudinary.com/ddojzbfuy/image/upload/v1722693663/2Dia-Festa-547_dbixea.jpg",
  "https://res.cloudinary.com/ddojzbfuy/image/upload/v1722693662/2Dia-Festa-200_l7agac.jpg",
  "https://res.cloudinary.com/ddojzbfuy/image/upload/v1722693662/2Dia-Festa-241_fxu8ek.jpg",
  "https://res.cloudinary.com/ddojzbfuy/image/upload/v1722693661/2Dia-Festa-236_d05tkw.jpg",
  "https://res.cloudinary.com/ddojzbfuy/image/upload/v1722693660/2Dia-Festa-53_hgwym9.jpg",
  "https://res.cloudinary.com/ddojzbfuy/image/upload/v1722693660/2Dia-Festa-172_t0w4bb.jpg",
  "https://res.cloudinary.com/ddojzbfuy/image/upload/v1722693660/2Dia-Festa-79_twwskm.jpg",
  "https://res.cloudinary.com/ddojzbfuy/image/upload/v1722693660/2Dia-Festa-102_cnnmxa.jpg",
  "https://res.cloudinary.com/ddojzbfuy/image/upload/v1722693660/2Dia-Festa-16_cf025g.jpg",
  "https://res.cloudinary.com/ddojzbfuy/image/upload/v1722693659/2Dia-Arena-128_flmycz.jpg",
  "https://res.cloudinary.com/ddojzbfuy/image/upload/v1722693658/2Dia-Arena-126_ywtvzo.jpg",
  "https://res.cloudinary.com/ddojzbfuy/image/upload/v1722693658/2Dia-Arena-111_u9skg2.jpg",
  "https://res.cloudinary.com/ddojzbfuy/image/upload/v1722693658/2Dia-Arena-112_gdzfup.jpg",
  "https://res.cloudinary.com/ddojzbfuy/image/upload/v1722693658/2Dia-Arena-91_ncywsb.jpg",
  "https://res.cloudinary.com/ddojzbfuy/image/upload/v1722693658/2Dia-Arena-90_qmrpec.jpg",
  "https://res.cloudinary.com/ddojzbfuy/image/upload/v1722693658/2Dia-Arena-17_oxfqth.jpg"
]


const Carrossel = () => {
  const [posAt, setPosAt] = useState(1)

  const [imgPrev, setImgPrev] = useState(imagelist[0])
  const [imgAt, setImgAt] = useState(imagelist[1])
  const [imgNext, setImgNext] = useState(imagelist[2])


  const handleNext = () => {
    setPosAt((posAt+1) % (imagelist.length -1))
  }

  const handlePrev = () => {
    setPosAt((posAt-1) % (imagelist.length -1))
  }

  const getImage = (pos) => {
    if (pos < 0)
      pos = imagelist.length + pos

    // if (pos >= imagelist.length)
    //   pos = pos - imagelist.length

    return imagelist[pos]
  }

  useEffect(() => {
    console.log(posAt)

    setImgPrev(getImage(posAt-1))
    setImgAt(getImage(posAt))
    setImgNext(getImage(posAt+1))

  }, [posAt])

  if (window.innerWidth > 800)
    return(
      <Flex align={"center"} justify={"space-between"} width={"100%"} height={"500px"} padding={"20px"}>
        <ChevronLeftIcon boxSize={100}  onClick={handlePrev} cursor={"pointer"} height={"100%"} _hover={{background: "black", opacity: "30%"}}/>

        <Image src={imgPrev} width={'150px'} height={"auto"}/>
        <Image src={imgAt}   maxH={'400px'} minW={'200px'} maxW={'400px'} height={"auto"} />
        <Image src={imgNext} width={'150px'} height={"auto"} />
        
        <ChevronRightIcon boxSize={100} onClick={handleNext} cursor={"pointer"} height={"100%"} _hover={{background: "black", opacity: "30%"}}/>
      </Flex>
    )
  
  else return (<></>)
}

const Aftermovie = () => {
  if (window.innerWidth > 800)
    return(
      <AspectRatio width="700px">
        <Box 
          as="iframe"
          title='TUSCA'
          src='https://www.youtube.com/embed/_efnzg6ekz4?si=OJubWyNV7PIy5XAz'
          allowFullScreen
        />
      </AspectRatio>
    )
  else return (<></>)
}


const Fotos = () => {
  return (
    <Stack
      justify={"center"} align={"center"}
      padding={"30px"} width={"100vw"}
      backgroundImage={Textura} backgroundRepeat={"no-repeat"} backgroundSize={"cover"}
    >
      <Heading color="white" fontSize='5xl'>TUSCA</Heading>
      
      <Stack align={"center"} width={"100%"}>
        
        <Carrossel />

        {/* TEXTO */}
        <Text color={"white"} justify={"center"} align={"center"} width={"80%"} fontSize={"2xl"} padding={"20px"}>
          A Taça Universitária de São Carlos, há 43 anos estimulando a prática do esporte e a integração sociocultural entre os participantes.
        </Text>
        <Text color={"white"} justify={"center"} align={"center"} width={"80%"} fontSize={"2xl"} padding={"20px"}>
          O maior evento universitário da América Latina promete entregar tudo e mais um pouco. São 4 dias de muita festa e integração, mais de 50 atrações em 2 palcos, mais de 40 horas de open bar, e muitas surpresas que ainda estão por vir.
        </Text>
        <Text color={"white"} justify={"center"} align={"center"} width={"80%"} fontSize={"2xl"} padding={"20px"}>
          De 14 a 17 de Novembro, o seu compromisso é na Arena Tusca, em São Carlos. Não perca essa chance de fazer parte da história e criar memórias inesquecíveis 
        </Text>

        {/* TÍTULO */}
        <Heading color={"white"} justify={"center"} align={"center"} width={"80%"} fontSize={"5xl"} padding={"20px"}>
          Venha fazer parte dessa Revolução
        </Heading>

        {/* AFTERMOVIE */}
        <Aftermovie />

      </Stack>

    </Stack>
  );
  }
    
  export default Fotos;