// import { Flex, Image, Text } from '@chakra-ui/react'
import { Flex } from '@chakra-ui/react'
// import { Link } from 'react-router-dom'

// import DadosRealizadores from "../../database/realizadores.json"
// import ig from "../../assets/Redes Sociais/tiktok.png"

// const LinkRealizadores = (props) => {
//     return (
//         <Link to={props.url} >
//             <Flex spacing="20px">
//                 <Image src={ig} alt={props.alt} width={"30px"} margin={"5px"}/>
//                 <Text>{props.arroba}</Text>
//             </Flex>
//         </Link>
//     )
// }


// const ListaRealizadores = () => {
//     return (
//         <>
//             {DadosRealizadores.realizacao.map((realizador) => {
//                 return <LinkRealizadores url={realizador.url} alt={realizador.alt} src={realizador.src} />
//             })}
//         </>
//     );
// }


const Realizadores = () => {
    return (
        <Flex padding="20px">
            {/* <ListaRealizadores /> */}
        </Flex>
    );
}


export default Realizadores