import { extendTheme } from '@chakra-ui/react'

const customTheme = extendTheme({
  colors: {
    verde: {
      500: "#00ff00",
    },
  },
  fonts: {
    heading: `'romalika', sans-serif`,
    body: `'conthrax', sans-serif`,
  },
})

export default customTheme
	