import React from 'react';
import { Outlet } from 'react-router-dom';

import { Header, Footer } from "./common"
import { ChakraProvider } from '@chakra-ui/react'

import customTheme from './customTheme'

function App() {
  return (
    <div>
      <ChakraProvider theme={customTheme}>
        <Header />
        <Outlet/>
        <Footer />
      </ChakraProvider>
    </div>
  );
}

export default App;