import { Flex, Box, Accordion, AccordionItem, AccordionButton, AccordionPanel, Heading, Stack } from "@chakra-ui/react"
import { MinusIcon, AddIcon } from '@chakra-ui/icons'

import DadosFaq from "../../database/faq.json"
import Textura from "../../assets/textura_2.png"


const CardFaq = (props) => {
  const listaRespostas = props.resposta.split('\n')


  return (
    <AccordionItem border="none" margin="5px" backgroundColor={"white"} borderRadius={"15px"}>
      {({ isExpanded }) => (
        <>
          <AccordionButton borderRadius='10px' _expanded={{ bg: 'black', color: 'white'}}>
            <Box as='span' flex='1' textAlign='left'> {props.pergunta} </Box>
            {isExpanded ? (<MinusIcon fontSize='12px' />) : (<AddIcon fontSize='12px' />)}
          </AccordionButton>

          <AccordionPanel pb={4}>
            { listaRespostas.map( (paragrafo) => <p style={{textIndent:"2em"}}>{paragrafo}</p> ) }
          </AccordionPanel>
        </>
      )}
    </AccordionItem>
  )
}


const ListaPerguntas = () => {
  return (
    <>
      {DadosFaq.duvidas.map((duvida) => {
        return (
          <CardFaq pergunta={duvida.pergunta} resposta={duvida.resposta} />
      )})}
    </>
  );
}

const FAQ = () => {
  return (
    <Stack
      justify={"center"} align={"center"} 
      padding={"30px"} width={"100vw"}
      backgroundImage={Textura} backgroundRepeat={"no-repeat"} backgroundSize={"cover"}
    >
      <Heading color="white" size='2xl'>FAQ</Heading>

      <Flex
        justify={"center"} padding={"40px"}
      >
        <Accordion allowToggle width={"70vw"}>
          <ListaPerguntas />
        </Accordion>

      </Flex>
    </Stack>

  );
}

export default FAQ;