import { Flex, Stack, Text } from '@chakra-ui/react'

import RedesSociais from './redesSociais';
import Realizadores from './realizadores';


const Footer = () => {
  const isRow = (window.innerWidth > 800)

  return (
    <Flex
      align={"center"} justify={"space-around"} padding={"50px"} width={"100vw"}
      direction={{ base: isRow ? "row" : "column"}} color="white" 
      bgColor={"black"}
    >
      <RedesSociais />

      <Stack align={"center"}>
        <Text fontSize={'sm'} align={"center"}>© 2024 por Torneio Universitário de São Carlos - TUSCA</Text>
        <Text fontSize={'sm'} align={"center"}>Todos os direitos reservados</Text>

        <Realizadores />
      </Stack>


    </Flex>
  );
}
  
export default Footer;