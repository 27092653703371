import { Flex, Image, Stack, Text } from '@chakra-ui/react'
import { Link } from 'react-router-dom'

import DadosRedesSociais from "../../database/redesSociais.json"

const LinkRedeSocial = (props) => {
    return (
        <Link to={props.url} >
            <Flex spacing="20px">
                <Image src={props.src} alt={props.alt} width={"20px"} margin={"5px"} />
            </Flex>
        </Link>
    )
}


const ListaRedesSociais = () => {
    return (
        <>
            {DadosRedesSociais.redesSociais.map((rede) => {
                return <LinkRedeSocial url={rede.url} alt={rede.alt} src={rede.src} arroba={rede.arroba} />
            })}
        </>
    );
}


const RedesSociais = () => {
    return (
        <Stack align={"center"}>
            <Text fontSize={'sm'}>Fique por dentro das novidades!</Text>
            <Flex>
                <ListaRedesSociais />
            </Flex>
        </Stack>
    );
}


export default RedesSociais