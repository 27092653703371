import { Flex, Button, Stack, Image } from '@chakra-ui/react'
import { Link } from 'react-router-dom';

import Logo from "../../assets/logo-branca.png"

const handleRedirect = () => {
  window.location.href = "https://blacktag.com.br/eventos/17796/tusca-2024/ingressos"
}


const Header = () => {
    return (
      <Stack align="center" bgColor={"black"}>
        <Flex align="center" width={"70%"} justify={"space-between"} padding={"40px"}>

          <Link to={'./'}>
            <Image src={Logo} alt={"Logo TUSCA"} minW={'120px'} width={"15vw"} />
          </Link>
          
          <Button onClick={handleRedirect} borderRadius={"90"} minW={'120px'} width={"vw"} colorScheme='verde'> INGRESSOS </Button>

        </Flex>
      </Stack>
    );
  }
  
export default Header;